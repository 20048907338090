@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;500;600&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif !important;
  font-weight: 500 !important;
}

.overflow {
  overflow-x: hidden;
}

.color-primary {
  color: #256381;
  text-decoration: none !important;
}
.color-primary:hover {
  color: #256381;
}

.bg-jabon {
  background-color: #256381;
}

.a {
  transition: 0.3s;
  text-decoration: none;
}
.a:hover {
  transition: 0.3s;
  transform: scale(1.075);
}

.btn-primary {
  background-color: #256381 !important;
  border: #256381 !important;
}

.fab {
  color: #256381 !important;
  border: #256381 !important;
}
.fa-copyright {
  color: #256381 !important;
}
.fs-13 {
  font-size: 13px;
}

.index {
  z-index: 1 !important;
}
.z-index {
  z-index: 100 !important;
}

.pointer {
  cursor: pointer;
}

/* navbar */
.overflow-navbar {
  overflow: visible !important;
}

/*banner*/
.sansita {
  font-family: 'Sansita Swashed', cursive;
}

.home-banner {
  height: 700px;
  background: url(/static/media/fondo.d274ba32.jpg) no-repeat center/cover;
}

/*minibanner*/
.mini-banner {
  height: 400px;
  background: url(/static/media/minibanner.1e091cc6.jpg) no-repeat center/cover fixed;
}

/*footer*/
.footer-item {
  list-style: none;
}

.flex-footer {
  display: flex;
  justify-content: center;
}

/*about*/
.about-img {
  background: url(/static/media/logo.9d6b0249.jpg) center center/cover;
}

/*características*/
.caracteristicas-font {
  color: black;
  text-decoration: none;
}
@media (max-width: 992px) {
  .caracteristicas-font {
    font-size: 20px;
    text-align: center;
  }
}

/*contacto*/
.img-contacto {
  display: flex;
  justify-content: center;
}
.contacto-img {
  width: 450px;
}

/*sidebar*/
.list-group-item-secondary {
  background: #256381 !important;
}

/*productos*/
.iconos {
  width: 300px;
}

.img-swiper {
  height: 500px;
  width: 100%;
}

@media (max-width: 991px) {
  .img-swiper {
    height: 375px;
  }
}
@media (max-width: 767px) {
  .img-swiper {
    height: 500px;
  }
}
@media (max-width: 575px) {
  .img-swiper {
    height: 375px;
  }
}

.icono {
  width: 100px;
}

@media (max-width: 991px) {
  .icono {
    width: 80px;
  }
}

/*productos page*/
.productos-banner {
  height: 600px;
  background: url(/static/media/banner.db932202.jpg) no-repeat center center/cover;
}

.img-background {
  height: 250px;
  width: 100%;
}

@media (max-width: 991px) {
  .img-background {
    height: 210px;
  }
}
@media (max-width: 767px) {
  .img-background {
    height: 250px;
  }
}
@media (max-width: 480px) {
  .img-background {
    height: 200px;
  }
}

